import React from "react"
import { Container, Header, Segment } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import EmailSignupFrom from "../components/signup-form"

const Subscribe = () => (
  <Layout>
    <SEO
      title="Subscribe to SixEye"
      description="Subscribe to our mailing list to get updates straight to your inbox."
    />
    <Container text>
      <Header as="h1">Stay in touch</Header>
      <p>Sign up for email updates</p>
      <Segment basic>
        <EmailSignupFrom />
      </Segment>
    </Container>
  </Layout>
)

export default Subscribe
