import React from "react"

import { Form, Header, Message } from "semantic-ui-react"

import addToMailchimp from "gatsby-plugin-mailchimp"

import LinkExternal from "./link-external"

/**
 * Semantic UI React Checkbox has a bug when interacting with state
 */
const FormCheckbox = props => (
  <div className="field">
    <div className="ui checkbox">
      <input
        type="checkbox"
        id={props.id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  </div>
)

export default class EmailSignupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: ``,
      firstName: ``,
      lastName: ``,
      functionIntegrator: false,
      functionManufacturer: false,
      rxEmail: false,
      loading: false,
      messageState: ``,
      messageHeader: ``,
      messageContent: ``,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.clear = this.clear.bind(this)
  }

  clear() {
    this.setState({
      email: ``,
      firstName: ``,
      lastName: ``,
      functionIntegrator: false,
      functionManufacturer: false,
      rxEmail: false,
    })
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    })
  }

  handleCheckboxChange({ target: { name, checked } }) {
    this.setState({
      [name]: checked,
    })
  }

  async handleSubmit(e) {
    e.preventDefault()

    const {
      email,
      firstName,
      lastName,
      functionIntegrator,
      functionManufacturer,
      rxEmail,
    } = this.state

    this.setState({ loading: true })

    let fields = {
      FNAME: firstName,
      LNAME: lastName,
      "gdpr[5105]": rxEmail ? "Y" : "",
    }
    if (functionIntegrator) {
      fields["group[15441][1]"] = "1"
    }
    if (functionManufacturer) {
      fields["group[15441][2]"] = "2"
    }

    try {
      const result = await addToMailchimp(email, fields)

      if (result.result === "success") {
        this.clear()
        this.setState({
          messageState: `success`,
          messageHeader: `Sign up complete`,
          messageContent: `Thank you for signing up to hear more about SixEye! We'll be in touch.`,
        })
      } else {
        this.setState({
          messageState: `error`,
          messageHeader: `Sign up failed`,
          messageContent: result.msg,
        })
      }
    } catch (e) {
      if (e.message === "Timeout") {
        let newState = {
          messageState: `error`,
          messageHeader: `Request timed out`,
          messageContent: `Please try again.`,
        }
        if (navigator.userAgent.search("Firefox") >= 0) {
          newState.messageContent = `This may be because you have tracking protection enabled, which is blocking access to Mailchimp.`
        }
        this.setState(newState)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <Form
        loading={this.state.loading}
        success={this.state.messageState === "success"}
        error={this.state.messageState === "error"}
        onSubmit={this.handleSubmit}
      >
        <Form.Input
          id="signup-form-input-email"
          label="Email address"
          name="email"
          placeholder="mel@example.com"
          value={this.state.email}
          onChange={this.handleChange}
          required
        />
        <Form.Group widths="equal">
          <Form.Input
            id="signup-form-input-first-name"
            label="First name"
            name="firstName"
            placeholder="First name"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
          <Form.Input
            id="signup-form-input-last-name"
            label="Last name"
            name="lastName"
            placeholder="Last name"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group grouped>
          <Header as="h3">Company function</Header>
          <FormCheckbox
            id="signup-form-function-check-integrator"
            label="Systems integrator"
            name="functionIntegrator"
            checked={this.state.functionIntegrator}
            onChange={this.handleCheckboxChange}
          />
          <FormCheckbox
            id="signup-form-function-check-manufacturer"
            label="Manufacturer"
            name="functionManufacturer"
            checked={this.state.functionManufacturer}
            onChange={this.handleCheckboxChange}
          />
        </Form.Group>
        <Form.Group grouped>
          <Header as="h3">Marketing permissions</Header>
          <p>
            SixEye will use the information you provide on this form to email
            you with updates and marketing. Please confirm that you're happy to
            receive these emails from us:
          </p>
          <FormCheckbox
            id="signup-form-check-rx-email"
            label="Yes, I am happy to receive email updates"
            name="rxEmail"
            checked={this.state.rxEmail}
            onChange={this.handleCheckboxChange}
          />
          <p>
            You can change your mind at any time by clicking the unsubscribe
            link in the footer of any email you receive from us, or by
            contacting us at marketing@sixeye.net. We will treat your
            information with respect. By clicking below, you agree that we may
            process your information in accordance with these terms.
          </p>
          <p>
            We use Mailchimp as our marketing platform. By clicking below to
            subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{" "}
            <LinkExternal href="https://mailchimp.com/legal/">
              Learn more about Mailchimp's privacy practices here.
            </LinkExternal>
          </p>
        </Form.Group>
        <Message success>
          <Message.Header>{this.state.messageHeader}</Message.Header>
          <Message.Content
            dangerouslySetInnerHTML={{ __html: this.state.messageContent }}
          />
        </Message>
        <Message error>
          <Message.Header>{this.state.messageHeader}</Message.Header>
          <Message.Content
            dangerouslySetInnerHTML={{ __html: this.state.messageContent }}
          />
        </Message>
        <Form.Button content="Submit" />
      </Form>
    )
  }
}
